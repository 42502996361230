<template>
    <div class="questionnaire-template-list">
      <!-- 查询区域 -->
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="模板名称">
          <el-input v-model="searchName" placeholder="请输入模板名称"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-input v-model="searchName" placeholder="请输入模板名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 操作按钮区域 -->
      <div class="operation-buttons">
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增模板</el-button>
        <el-button type="primary" >前往题目库</el-button>
      </div>
      <!-- 表格区域 -->
      <el-table :data="tableData" border stripe>
        <!-- 名称
        行业
        类型
        标题
        描述
        售价
        备注
        来源
        是否公开--分享配置
        增加流转规则：顺序答题/特殊规则题 -->
        <el-table-column prop="name" label="序号" width="180"/>
        <el-table-column prop="name" label="模板名称" width="180"/>
        <el-table-column prop="description" label="描述"/>
        <el-table-column prop="description" label="行业"/>
        <el-table-column prop="description" label="标签"/>
        <el-table-column prop="description" label="类型"/>
        <el-table-column prop="description" label="标题"/>
        <el-table-column prop="lastEdited" label="来源" width="180"/>
        <el-table-column prop="lastEdited" label="是否公开" width="180"/>
        <el-table-column prop="lastEdited" label="最后编辑时间" width="180"/>
        <el-table-column label="操作" width="350">
          <template slot-scope="{ row }">
            <el-button size="small" @click="editTemplate(row)" class="table-operation-button">添加题目</el-button>
            <el-button size="small" @click="editTemplate(row)" class="table-operation-button">复制</el-button>
            <el-button size="small" @click="editTemplate(row)" class="table-operation-button">配置可见</el-button>
            <el-button size="small" @click="editTemplate(row)" class="table-operation-button">前往发布</el-button>
            <el-button size="small" @click="editTemplate(row)" class="table-operation-button">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteTemplate(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 新增模板的对话框 -->
      <el-dialog title="新增模板" :visible.sync="dialogVisible">
        <el-form :model="newTemplate">
          <el-form-item label="模板名称" :label-width="formLabelWidth">
            <el-input v-model="newTemplate.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="描述" :label-width="formLabelWidth">
            <el-input v-model="newTemplate.description" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addTemplate">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogVisible: false,
        formLabelWidth: '120px',
        searchName: '',
        newTemplate: {
          name: '',
          description: ''
        },
        tableData: [
          // 初始问卷模板数据
          {
            name:"考试题"
          }
        ]
      };
    },
    methods: {
      handleSearch() {
        // 查询模板的逻辑，根据searchName来过滤或者请求数据
        console.log('查询模板名称:', this.searchName);
      },
      handleAdd() {
        // 显示新增模板的对话框
        this.dialogVisible = true;
      },
      addTemplate() {
        // 新增模板的逻辑，通常需要将newTemplate发送到服务器
        console.log('新增模板:', this.newTemplate);
        // 添加成功后，关闭对话框并清空表单
        this.dialogVisible = false;
        this.newTemplate = { name: '', description: '' };
        // 这里可以通过调用API重新获取数据或直接将新模板添加到tableData中
      },
      editTemplate(template) {
        // 编辑模板的逻辑
        console.log('编辑模板', template);
      },
      deleteTemplate(template) {
        // 删除模板的逻辑
        console.log('删除模板', template);
        // 这里可以添加弹窗确认删除操作
        // 进行实际的删除操作，例如调用API或者更新data中的tableData
      }
    }
  };
  </script>
  
  <style>
  .questionnaire-template-list {
    margin: 20px;
  }
  
  .operation-buttons {
    margin-bottom: 20px;
  }
  /* 单独为表格操作按钮添加样式来调整间距 */
  .table-operation-button {
    margin: 0 5px 5px 0; /* 右边距和底边距 */
  }
  </style>